.listItem {
  display: inline-block;
}

.button {
  background: none;
  border: none;
  font-family: inherit;
  font-size: inherit;
  cursor: pointer;
}

.circle {
  border-radius: 50%;
  background-size: 120px;
  width: 50px;
  height: 50px;
  margin: 0 auto 5px;
}
