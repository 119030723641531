.app {
  min-height: 100vh;
  position: relative;
}

.main {
  //height: calc(100vh - 250px);
  //overflow: auto;
  //padding: 0 1.5rem 3rem;
  padding-top: 10px !important;
  padding-bottom: 230px !important;
}

@media screen and (max-width: 768px) {
  .main {
    padding-bottom: 474px !important;
  }
}

.clearfix::after {
  display: table;
  content: "";
  clear: both;
}

.visually-hidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.visually-hidden:focus {
  clip: auto;
  height: auto;
  overflow: auto;
  position: absolute;
  width: auto;
}

@media screen and (min-width: 769px) {
  .mobile-only {
    display: none;
  }
}
