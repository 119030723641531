.container {
  white-space: nowrap;
  overflow-x: scroll;
}

.option {
  display: inline-block;
  max-width: 120px;
  margin-right: 10px;
  box-sizing: content-box;
}

.selected {
  border: 4px solid #bdbd7f;
}

.subline {
  text-align: center;
}
