.container {
  text-align: center;
  padding-bottom: 30px;
  margin-top: 20px;
}

@media screen and (min-width: 769px) {
  .container {
    margin-top: 0;
  }
}

.desc {
  font-size: 18px;
  margin-bottom: 7px;
}

.option {
  font-size: 18px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.input {
  margin-right: 7px;
}

.label {
  vertical-align: middle;
}
