.progressContainer {
  position: fixed;
  top: 65px;
  left: 0;
  width: 100%;

  display: flex;
  vertical-align: center;
  justify-content: center;
  padding: 0 10px;

  progress {
    max-width: 500px;
  }
}

.hidden {
  display: none;
}

.uploadButtonIcon {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
}

.configRow {
  margin-bottom: 15px;
}

.configHeading {
  font-size: 20px;
}

button.button {
  border-radius: 0;
  border: 2px solid #222 !important;
  color: #222;
  background-color: white !important;
  opacity: 1 !important;
  -webkit-transition: 0.1s background-color linear, 0.1s color linear;
  -moz-transition: 0.1s background-color linear, 0.1s color linear;
  -o-transition: 0.1s background-color linear, 0.1s color linear;
  transition: 0.1s background-color linear, 0.1s color linear;

  &:hover {
    color: white;
    background-color: #222 !important;
  }
}
