.checkoutNav {
  display: flex;
  align-items: center;
}

.topCheckoutNav {
  margin-bottom: 10px;
}

.totalSum {
  margin-right: 15px;
}

@media screen and (min-width: 769px) {
  .totalSum {
    font-size: 18px;
  }
}
