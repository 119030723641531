.heading {
  font-size: 24px;
  margin-bottom: 10px;
  text-align: center;
}

.main {
  margin-top: 20px;
}

.bottomNav {
  margin-top: 20px;
}

.cartSummaryContainer {
  margin-bottom: 25px;
}
