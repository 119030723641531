.price {
  float: right;
}

.totalPrice {
  font-weight: 700;
}

.listItem {
  font-size: 18px;
  margin-bottom: 7px;
}

.last {
  border-bottom: 1px black solid;
}
