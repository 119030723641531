.modal {
  max-width: calc(100% - 20px);
}

.buttons {
  width: 100%;
}

.button {
  display: block;
  text-align: center;
  margin-bottom: 7px;
}

@media screen and (min-width: 420px) {
  .button {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 5px;
  }
}
