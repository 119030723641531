.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  > div:only-child {
    margin: 0 auto;
  }
}
