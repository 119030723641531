.row {
  position: relative;
  padding: 5px 0;
  border-bottom: 1px solid lightgrey;
}

.noBorder {
  border: none;
}

.image {
  cursor: pointer;
}

.actionButtons {
  display: flex;
  flex-direction: column;
}

.actionButton {
  text-align: right;
  margin-bottom: 10px;

  button {
    cursor: pointer;
    border: none;
    font-size: inherit;
    color: inherit;
    background: none;

    span {
      //font-size: 18px;
      margin-right: 7px;
    }
  }

  i {
    font-size: 20px;
  }
}

.frameName {
  font-size: 22px;
}

.rightColumn {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.price {
  font-size: 24px;
}

@media screen and (max-width: 768px) {
  .maxWidth {
    max-width: 300px;
    margin: 0 auto;
    padding: 0 !important;
  }

  .row {
    margin-bottom: 25px !important;
  }

  .rightColumn {
    margin-top: 15px;
    flex-direction: row;
    align-items: center;
  }

  .actionButtons {
    flex-direction: row;
  }

  .actionButton {
    margin-right: 10px;

    span {
      clip: rect(1px, 1px, 1px, 1px);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    i {
      font-size: 24px;
    }
  }
}
