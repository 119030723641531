.container {
  display: flex;
  height: 100%;
  align-items: center;
  width: 100%;
  background-color: lightgrey;
}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.label {
  cursor: pointer;
  width: 100%;
  height: 100%;
}
