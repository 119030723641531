.container {
  width: 100%;
  //text-align: right;
}

.footer {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 3rem 1.5rem;
  background-color: #fafafa;
}

.nav {
  ul {
    text-align: center;
  }
  li {
    display: inline-block;
    padding-right: 1em;

    text-transform: uppercase;
    font-size: 10px;
    font-family: futura-pt, Arial, Helvetica, sans-serif;
    letter-spacing: 0.35em;
    line-height: 1em;
  }

  a {
    color: #333;
  }
}

.socialMedia {
  text-align: center;

  ul {
    margin-top: 5px;
    display: flex;
    justify-content: space-around;
  }

  li {
    display: inline-block;
    font-size: 30px;

    a {
      color: inherit;
    }
  }
}

.copyright {
  text-align: center;
}
