.container {
  position: relative;
  box-shadow: -5px 5px 50px 0 rgba(0, 0, 0, 0.1);
  margin: auto;
}

.sizer {
  background-size: 150px 150px;
  background-repeat: repeat;
  width: 100%;
}

.overlay {
  display: none;
}

.container:hover .overlay {
  display: block;
}

.base {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.interior {
  position: absolute;
  background-color: white;
}

.innerShadow {
  box-shadow: inset -6px 6px 30px 0 rgba(0, 0, 0, 0.2);
}

.image {
  position: absolute;
  overflow: hidden;

  img {
    width: 100%;
  }
}
