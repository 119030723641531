.container {
  padding: 10px 20px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  width: 150px;
}

.cart {
  color: inherit;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  align-items: center;
}

.items {
  display: inline-block;
  border: 1px solid black;
  border-radius: 50%;
  width: 28px;
  height: 28px;

  span {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
