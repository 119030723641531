.row {
  font-size: 1.2em;
  text-align: center;
}

.button {
  margin-top: 10px;
  text-align: center;
  display: none;
}

@media screen and (min-width: 769px) {
  .button {
    display: block;
  }
}
